import React from "react";
import "./ResourcesList.css";

import ResourceIcon03 from "../../assets/ResourceIcon03.webp";
import ResourceIcon04 from "../../assets/ResourceIcon04.webp";
import ResourceIcon05 from "../../assets/ResourceIcon05.webp";

export const ResourcesList = () => {
  return (
    <div className="resources-section">
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}><td><h3>Learn the Basics</h3><p>Get the background you need to understand what happens on your local school board.</p></td>
            <td className="right-align">
              <img alt="location" className="resources-table-image" src={ResourceIcon03} />
            </td>
            </th>
          </tr>
          <tr>
            <td>
              <h5>What Is a School Board?</h5>
              <p>Live and recorded sessions with experts in competency-based education</p>  
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Where to Find School Data</h5>
              <p>Use this handy list of informative links.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Know Your School Board</h5>
              <p>Who’s who at a typical school board meeting.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>What Happens at a School Board Meeting?</h5>
              <p>There are 14,000 school boards in the US. Most follow the same basic rules.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Elected or Appointed?</h5>
              <p>Some boards are elected, some appointed. Which is yours?</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
        </table>
      </div>
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>
              <td><h3>Get Involved</h3>
                <p>Dive into the issues challenging your community, then exercise your right to raise your voice for others to hear.</p></td>
              <td className="right-align">
                <img alt="location" className="resources-table-image" src={ResourceIcon04} />
              </td>
            </th>
          </tr>
          <tr>
            <td>
              <h5>How to Write a Letter to Your School Board</h5>
              <p>Voice a concern, make a suggestion, or simply ask a question.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Be a changemaker</h5>
              <p>Here&apos;s how to do it.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Five Questions to Ask Your School Board</h5>
              <p>It’s time to get curious about your school system.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Why Equity is Worth Fighting For</h5>
              <p>Schools can&apos;t all be the same, but they can be equitable</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
        </table>
      </div>
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>
              <td>
                <h3>Rethinking High School</h3>
                <p>Discover how XQ is transforming high school education and learn the innovative ways all of us–including students–can be changemakers.</p>
              </td>
              <td className="right-align">
                <img alt="location" className="resources-table-image" src={ResourceIcon05} />
              </td>
            </th>
          </tr>
          <tr>
            <td>
              <h5>High Schools and School Boards</h5>
              <p>Can school boards help transform high school? Yes, they can.</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Students and School Boards</h5>
              <p>How can high school students make an impact?</p>
            </td>
            <td className="right-align"><a href="/">View</a></td>
          </tr>
        </table>
      </div>
    </div>
  );
};
