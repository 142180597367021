import React from "react";
import "./ResourcesSection.css";


export const ResourcesSection = () => {
  return (
    <div>
      {/* <MoreResources config={config.resources} /> */}
    </div>
  );
};
