import {
  CtaConfig,
  GettingStartedConfig,
  HomePageHeaderConfig,
  HomePageHookContent,
  HomePageStatsConfig,
  ResourceArticleConfig,
  ResourcesConfig,
  ResultPageHeader,
  SharedErrorMessaging,
  YellowPurpleBlocksConfig,
  ImageNode,
} from "../../lib/types/cmsConfigs";

export interface CmsParser {
  parse(data: any): any;
}

export const ResultPageHeaderParser = {
  parse: (data: any): ResultPageHeader => {
    return {
      headingText: data["resultHeaderText"],
      inputLabel: data["resultHeaderInputLabel"],
    };
  },
};

export const HomePageHeaderParser = {
  parse: (data: any): HomePageHeaderConfig => {
    return {
      headingHtml: data["homePageHeaderHeading"],
      inputLabel: data["homePageHeaderInputLabel"],
      videoUrl: data["homePageVideoUrl"],
      featureImageUrl: data["homePageHeaderHeroImage"]
        ? data["homePageHeaderHeroImage"]["node"]["sourceUrl"]
        : "",
    };
  },
};

export const HomePageStatsParser = {
  parse: (data: any): HomePageStatsConfig => {
    return {
      headingHtml: data["homePageStatsHeading"],
      subtext: data["homePageStatsSubtext"],
      imageDesktopUrl: data["homePageStatsImageDesktop"]
        ? data["homePageStatsImageDesktop"]["node"]["sourceUrl"]
        : "",
      imageMobileUrl: data["homePageStatsImageMobile"]
        ? data["homePageStatsImageMobile"]["node"]["sourceUrl"]
        : "",
    };
  },
};

export const HookContentParser = {
  parse: (data: any): HomePageHookContent => {
    return {
      heading: data["homePageHookContentHeading"],
      body: data["homePageHookContentBody"],
      carousel: data["homePageHookContentCarousel"]
        ? data["homePageHookContentCarousel"].map(
            (el: { text: string; image: ImageNode }) => {
              return {
                text: el?.text,
                imageUrl:
                  el.image && el.image["node"]
                    ? el.image["node"]["sourceUrl"]
                    : "",
              };
            },
          )
        : [],
    };
  },
};

export const CtaParser = {
  parse: (data: any): CtaConfig => {
    return {
      cta: data["sharedCta"],
    };
  },
};

export const GettingStartedParser = {
  parse: (data: any): GettingStartedConfig => {
    return {
      heading: data["gettingStartedHeading"],
      subtext: data["gettingStartedSubtext"],
      featureImageUrl: data["gettingStartedFeatureImage"]
        ? data["gettingStartedFeatureImage"]["node"]["sourceUrl"]
        : "",
      buttonText: data["gettingStartedButtonText"],
    };
  },
};

export const ResourcesParser = {
  parse: (data: any): ResourcesConfig => {
    return {
      heading: data["resourcesHeading"],
      copy: data["resourcesCopy"],
    };
  },
};

export const ResourceArticleParser = {
  parse: (data: any): ResourceArticleConfig[] => {
    return data.map((node: any) => {
      return {
        heading:
          node["node"]["moreResourcesArticleGroup"][
            "moreResourcesArticleHeading"
          ],
        url: node["node"]["moreResourcesArticleGroup"][
          "moreResourcesArticleUrl"
        ],
        imageUrl: node["node"]["moreResourcesArticleGroup"][
          "moreResourcesArticleImage"
        ]
          ? node["node"]["moreResourcesArticleGroup"][
              "moreResourcesArticleImage"
            ]["node"]["sourceUrl"]
          : "",
        resourceTagName: node["node"]["moreResourcesArticleGroup"][
          "moreResourcesArticleTags"
        ]
          ? node["node"]["moreResourcesArticleGroup"][
              "moreResourcesArticleTags"
            ]["nodes"][0]["name"]
          : "",
      };
    });
  },
};

export const FooterParser = {
  parse: (data: any): any => {
    return {
      contactHeaderText: data["footerContactHeaderText"],
      contactLinkText1: data["footerContactLinkText1"],
      contactLinkUrl1: data["footerContactLinkUrl1"],
      contactLinkText2: data["footerContactLinkText2"],
      contactLinkUrl2: data["footerContactLinkUrl2"],
      contactLinkText3: data["footerContactLinkText3"],
      contactLinkUrl3: data["footerContactLinkUrl3"],
      socialHeaderText: data["footerSocialHeaderText"],
      socialFacebookUrl: data["footerSocialFacebookUrl"],
      socialTwitterUrl: data["footerSocialTwitterUrl"],
      socialInstagramUrl: data["footerSocialInstagramUrl"],
      legalHeaderText: data["footerLegalHeaderText"],
      legalLinkText1: data["footerLegalLinkText1"],
      legalLinkUrl1: data["footerLegalLinkUrl1"],
      legalLinkText2: data["footerLegalLinkText2"],
      legalLinkUrl2: data["footerLegalLinkUrl2"],
    };
  },
};

export const ErrorMessagingParser = {
  parse: (data: any): SharedErrorMessaging => {
    return {
      addressNotFoundMessage: data["sharedAddressNotFoundErrorMessage"],
    };
  },
};

export const YellowPurpleBlocksParser = {
  parse: (data: any): YellowPurpleBlocksConfig => {
    return {
      yellowTitle: data["yellowTitle"],
      yellowContent: data["yellowContent"],
      yellowButtonText: data["yellowButtonText"],
      yellowButtonLink: data["yellowButtonLink"]
        ? data["yellowButtonLink"]["url"]
        : "",
      yellowImageUrl: data["yellowImage"]
        ? data["yellowImage"]["node"]["sourceUrl"]
        : "",
      purpleTitle: data["purpleTitle"],
      purpleContent: data["purpleContent"],
      purpleButtonText: data["purpleButtonText"],
      purpleButtonLink: data["purpleButtonLink"]
        ? data["purpleButtonLink"]["url"]
        : "",
      purpleImageUrl: data["purpleImage"]
        ? data["purpleImage"]["node"]["sourceUrl"]
        : "",
    };
  },
};
