import { gql } from "@apollo/client";
import { GravityFormsForm } from "../generated/graphql";

import { client } from "../services/apollo";
// import { ADDRESS_FIELD_FIELDS } from "../components/GravityFormsFields/AddressField";
// import { CHECKBOX_FIELD_FIELDS } from "../components/GravityFormsFields/CheckboxField";
// import { DATE_FIELD_FIELDS } from "../components/GravityFormsFields/DateField";
// import { EMAIL_FIELD_FIELDS } from "../components/GravityFormsFields/EmailField";
// import { MULTI_SELECT_FIELD_FIELDS } from "../components/GravityFormsFields/MultiSelectField";
// import { NAME_FIELD_FIELDS } from "../components/GravityFormsFields/NameField";
// import { PHONE_FIELD_FIELDS } from "../components/GravityFormsFields/PhoneField";
// import { RADIO_FIELD_FIELDS } from "../components/GravityFormsFields/RadioField";
// import { SELECT_FIELD_FIELDS } from "../components/GravityFormsFields/SelectField";
import { TEXT_AREA_FIELD_FIELDS } from "../components/GravityFormsFields/TextAreaField";
import { TEXT_FIELD_FIELDS } from "../components/GravityFormsFields/TextField";
// import { TIME_FIELD_FIELDS } from "../components/GravityFormsFields/TimeField";
// import { WEBSITE_FIELD_FIELDS } from "../components/GravityFormsFields/WebsiteField";

const GET_FORM = gql`
  query getForm {
    gfForm(id: 1, idType: DATABASE_ID) {
      title
      description
      id
      confirmations {
        isDefault
        message
      }
      formFields(first: 500) {
        nodes {
          id
          type
          ... on TextField {
            ...TextFieldFields
          }
          ... on TextAreaField {
            ...TextAreaFieldFields
          }
        }
      }
    }
  }
  ${TEXT_AREA_FIELD_FIELDS}
  ${TEXT_FIELD_FIELDS}
`;

export default async function getGravityForm(formId: number): Promise<GravityFormsForm | undefined> {
  const result = await client
    .query({
      query: GET_FORM,
      variables: { formId },
    });
  return result?.data?.gfForm;
}
