import React from "react";
import { useState, useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Snackbar, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { StyledButton } from "./StyledButton";
import { createSearchParams, useNavigate } from "react-router-dom";
import "./AddressSearch.css";
import { EventType, trackEvent } from "../lib/analytics";
import { SharedErrorMessaging } from "../lib/types/cmsConfigs";
import { ReactComponent as LocationIcon } from "../assets/Location.svg";

interface AddressSearchProps {
  findAddressDataTestId: string;
  additionalOnClick?: (formattedAddress: string) => void;
  label?: string;
  errorMessagingConfig?: SharedErrorMessaging;
  isResultsScreen?: boolean;
  value?: string;
  id?: string;
  placeholder?: string;
}

export const AddressSearch = (props: AddressSearchProps) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [text, setText] = useState(props.value);
  const [place, setPlace] = useState<any>("");
  const [enterPressed, setEnterPressed] = useState(false);

  let settingPlace = false;
  const options = {
    componentRestrictions: { country: "us" },
    types: ["geocode"],
  };

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
      options: options,
    });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      settingPlace = true;
      setPlace(place);
      if (place && place.formatted_address) {
        setText(place.formatted_address);
      }
      settingPlace = false;
    },
    options: options,
  });
  const navigate = useNavigate();

  const placeIsUnset =
    !place || JSON.stringify(place) === JSON.stringify({ name: "" });

  const handleClick = async () => {
    await submitPlace(place);
  };

  const submitPlace = async (foundPlace: any) => {
    if (foundPlace?.geometry?.location) {
      trackEvent(EventType.SearchClick, obfuscatedAddress(foundPlace));
      const location = foundPlace.geometry.location;
      navigate({
        pathname: "/results/",
        search: `?${createSearchParams({
          address: foundPlace.formatted_address,
          lat: location.lat(),
          lng: location.lng(),
        })}`,
      });
      if (props.additionalOnClick) {
        //props.additionalOnClick(foundPlace.formatted_address);
      }
    } else {
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (enterPressed && placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails: any) => {
          setPlace(placeDetails);
          submitPlace(placeDetails);
        },
      );
  }, [placePredictions]);

  useEffect(() => {
    setText(props.value);
  }, []);

  return (
    <>
      <FormControl sx={{ width: "100%" }} id={props.id ? props.id : "lookup"}>
        {/*!props?.isResultsScreen && (
          <InputLabel
            disableAnimation={true}
            shrink
            className="address-search-label"
          >
            {props.label}
          </InputLabel>
        )*/}
        <LocationIcon />
        <TextField
          placeholder={
            props.placeholder
              ? props.placeholder
              : "Enter your address or ZIP code"
          }
          style={
            !props?.isResultsScreen
              ? { paddingBottom: "20px" }
              : { width: "100%", border: "none" }
          }
          className="address-search"
          inputRef={materialRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setText(event.target.value);
            if (!settingPlace) {
              setPlace(null);
            }
          }}
          onKeyUp={(event) => {
            getPlacePredictions({
              input: (event.target as HTMLInputElement).value,
            });
            if (placePredictions.length) {
              if (event.key === "Enter") {
                setEnterPressed(true);
                setText(placePredictions[0].description);
              } else {
                setEnterPressed(false);
              }
            }
          }}
          value={text ?? ""}
        ></TextField>

        <StyledButton
          disabled={placeIsUnset}
          dataTestid={props.findAddressDataTestId}
          variant="contained"
          label={props?.isResultsScreen ? "Change" : "Find yours"}
          handleClick={handleClick}
        ></StyledButton>
      </FormControl>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showErrorMessage}
        autoHideDuration={3000}
        onClose={() => setShowErrorMessage(false)}
        message={
          props.errorMessagingConfig?.addressNotFoundMessage ||
          "Address not found, please try again."
        }
        key="address-not-found-snackbar"
      />
    </>
  );
};

const obfuscatedAddress = (place: any) => {
  return {
    zip: place?.address_components?.filter((c: any) =>
      c.types.includes("postal_code"),
    )[0]?.short_name,
    state: place?.address_components?.filter((c: any) =>
      c.types.includes("administrative_area_level_1"),
    )[0]?.short_name,
  };
};
