import React, { useEffect } from "react";
// import { AddressLookupSection } from "./sections/AddressLookupSection";
import { useAboutPageEndpoint } from "../hooks/wordpressCms";
import { Backdrop, CircularProgress } from "@mui/material";
import { Footer } from "../common/Footer";
import { ResourcesSection } from "./sections/ResourcesSection";
import { ResourcesList } from "./sections/ResourcesList";
import BookIcon from "../assets/Book.png";

import ResourceIcon01 from "../assets/ResourceIcon01.svg";
import ResourceIcon02 from "../assets/ResourceIcon02.svg";

import "./About.css";

function About() {
  const { loadAboutPage, aboutPageConfig } = useAboutPageEndpoint();
  useEffect(() => {
    loadAboutPage();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal }}
        open={!aboutPageConfig}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {aboutPageConfig && (
        <>
          <div className="resources-section resources-heading">
            <img alt="location" className="floating-icon-left" src={ResourceIcon01} />
            <img alt="location" className="floating-icon-right" src={ResourceIcon02} />
            <img alt="location" className="book" src={BookIcon} />
            <h1>School Board Finder Resources</h1>
            <p>Your local school board is the leading agent of change for schools in your community. Learn how it operates and the role all of us can play in advocating for what we want in our schools—especially at the crucial high school level, when students are planning their futures. We have everything you need right here.</p>
          </div>
          <ResourcesSection />
          <ResourcesList />
          <Footer config={aboutPageConfig.footer} />
        </>
      )}
    </div>
  );
}

export default About;
